.ant-modal-footer {
  display: none !important;
}

.ant-modal-body {
  padding: 0 !important;
  width: fit-content !important;
}

.ant-modal {
  min-width: fit-content !important;
  border-radius: 10px;
}
.ant-modal-wrap {
  z-index: 1500 !important;
}

.ant-modal .anticon svg {
  display: none !important;
}
.ant-modal-close-x {
  display: none !important;
}
.ant-modal-content {
  box-shadow: none !important;
  width: fit-content !important;
}
