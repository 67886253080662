.PhoneInputInput {
  /* Your styles here */
  color: #666d92;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 44px;
  padding-left: 10px;
}

.PhoneInputInput:focus,
.PhoneInputInput:hover {
  border: 1px solid #3b82f680 !important;
}
.PhoneInput--focus .PhoneInputInput {
  border: 1px solid #3b82f680 !important;
}
.PhoneInput--focus .PhoneInputInput {
  /* Override the styles for the focused state */
  border: 1px solid #d9d9d9; /* Change to the desired style */
}

div.PhoneInput.PhoneInput--focus.PhoneInputInput {
  /* Override the styles for the focused state */
  border: 1px solid #d9d9d9; /* Change to the desired style */
}

Button:hover {
  /* Define your hover styles here */
  background-color: #0796a7 !important;
  color: #fff !important;
}
