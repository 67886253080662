@keyframes panel-animation {
  0% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
.control-panel-body {
  background-color: #27272b;
  border: 0.5px solid #0796a7;
  border-radius: 14px;
  width: auto;
  position: fixed;
  bottom: 5px;
  left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  padding: 18px;
  transition: all ease-out 200ms;
  animation: panel-animation 1s linear;
  z-index: 999;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
}
.media-element {
  position: fixed;
  bottom: 80px;
  left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  transition: all ease-out 100ms;
  animation: panel-animation 1s linear;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
@keyframes blink {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.maximize-btn {
  position: fixed !important;
  bottom: 15px;
  left: 15px;
  z-index: 1000;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px !important;
  height: 50px !important;
  background-color: #ff5e5e !important;
  animation: blink 2s ease-in-out infinite;
}
.recording-btn {
  animation: blink 2s ease-in-out infinite;
}

.call-view-container {
  position: fixed;
  bottom: 5px;
  /* left: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 3px;
  z-index: 1000;
  height: inherit;
  width: 100%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.call-view-inner {
  height: 100%;
  width: 100%;
  transform: translateX(0%);
  transition: transform 5s ease;
  overflow-x: auto;
  padding: 10px;
}

.local-stream-container {
  width: 300px;
  min-width: 200px;
  height: 100%;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  min-height: 200px;
}

.remote-stream-container {
  width: 300px;
  min-width: 200px;
  border-radius: 15px;
  overflow: hidden;
  min-height: 200px;
  border: '2px solid red';
}
