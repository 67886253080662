.ant-form-item {
  margin-bottom: 10px;
}

.ant-col {
  max-width: 100% !important;
}

.ant-upload-list-item-info {
  display: none;
}

.file-container .ant-upload-list-item-info {
  display: block;
}
