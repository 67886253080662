/* Optional: If you want the slides to maintain a specific aspect ratio */
.slide-container {
  aspect-ratio: 16/9; /* Change this ratio as needed (e.g., 4/3, 1/1, etc.) */
}

/* Optional: If you want the text to be centered on the slides */
.swiper-slide > span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.react-slideshow-container .nav:last-of-type {
  right: 30px !important;
}

.react-slideshow-container .nav:first-of-type {
  left: 30px !important;
}
